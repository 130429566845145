<template lang="pug">
    div
        v-snackbar(
        :timeout="3000"
        :top="true"
        :right="true"
        :color="alertColor"
        v-model="alertMsj")
            | {{ alertText }}
            v-btn(flat dark @click.native="alertMsj = false") {{ $t('cerrar') }}
        loader(v-if="$root.loader")
        template(v-else)
            header-component
                v-btn.no-margin(absolute dark fab top right color='primary' @click.stop="drawerRight = !drawerRight")
                    v-icon search
            v-navigation-drawer.filters.grey.darken-3(fixed v-model="drawerRight" right app width="246")
                v-form
                    v-toolbar.grey.darken-1(flat)
                        v-text-field(v-model="filter.query" :placeholder="$t('Buscar por nombre, tags...')" dark)
                        v-icon(dark) search
                    .filters-content
                        .white--text.mb-2 {{$t('Filtrar por')}}:
                        v-menu(
                            ref="timePicker"
                            :close-on-content-click="false"
                            v-model="timeModal"
                            full-width
                            width="320px"
                            lazy
                            offset-y
                            :return-value.sync='filter.hour'
                        )
                            v-text-field(
                                slot="activator"
                                :label="$t('Hora de partida')"
                                v-model="filter.hour"
                                readonly
                                dark
                                color="white"
                            )
                            v-time-picker(v-model="filter.hour" actions)
                                v-spacer
                                v-btn(flat color="primary" @click="timeModal=false") {{ $t('Cancelar') }}
                                v-btn(flat color="primary" @click="$refs.timePicker.save(filter.hour)") {{ $t('Guardar') }}
                        v-menu(:close-on-content-click="false" ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                            v-text-field(slot='activator' :label='$t("Desde")' v-model='filter.from' readonly dark color="white")
                            v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                v-spacer
                                v-btn(flat color='primary' @click.stop='modalDateStart = false') {{ $t('Cancelar') }}
                                v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{ $t('OK') }}
                        v-menu(:close-on-content-click="false" ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                            v-text-field(slot='activator' :label='$t("Hasta")' v-model='filter.to' readonly dark color="white")
                            v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                v-spacer
                                v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{ $t('Cancelar') }}
                                v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{ $t('OK') }}
                        // Rutas disponibles
                        template(v-if="tab == 0")
                            v-select(
                                v-model="filter.gender"
                                :items='gender'
                                item-value="id"
                                item-text="text"
                                :label='$t("Género")'
                                append-icon="keyboard_arrow_down"
                                autocomplete
                                :filter="vFilter"
                                dark
                                color="white"
                            )
                                template(slot="item" slot-scope="data")
                                    v-list-tile-content
                                        v-list-tile-title {{ $t(data.item.text) }}
                                template(slot="selection" slot-scope="data")
                                    | {{ $t(data.item.text) }}
                            v-select(
                                v-model="filter.age"
                                :items='ages'
                                item-value="id"
                                item-text="text"
                                :label='$t("Rango de edad")'
                                append-icon="keyboard_arrow_down"
                                autocomplete
                                :filter="vFilter"
                                dark
                                color="white"
                            )
                                template(slot="item" slot-scope="data")
                                    v-list-tile-content
                                        v-list-tile-title {{ $t(data.item.text) }}
                                template(slot="selection" slot-scope="data")
                                    | {{ $t(data.item.text) }}
                        v-divider.mb-4
                        .btns.btns-right
                            v-btn(@click="cleanFilter" color="primary") {{$t('Limpiar')}}
            v-content
                v-container.grid-list-xl(fluid)
                    h1 {{$t('Rutas compartidas')}}
                    v-tabs(v-model="tab")
                        v-tab {{ $t('Rutas disponibles')}}
                        v-tab {{ $t('Mis rutas aprobadas')}}
                        v-tab {{ $t('Mis rutas creadas')}}
                    v-tabs-items(v-model="tab")
                        v-tab-item(:value="0")
                            template(v-if="preload")
                                h3.my-2 {{$t('Cargando')}}...
                            template(v-else)
                                v-layout.my-2(row wrap v-if="routesAvailable.length")
                                    v-flex(xs12 sm6 md4 lg3 v-for="(route, i) in routesAvailable" :key="route.uuid")
                                        .sheet.grey.lighten-3
                                            .sheet-title.mb-2
                                                v-avatar.mr-2(:size="20")
                                                    img(v-if="photoUrl(route.user.photo).length" :src="photoUrl(route.user.photo)")
                                                    v-gravatar(v-else-if="Object.keys(route.user).length" :email="route.user.email")
                                                | {{ route.user.names }}
                                                h3.mt-3 {{ route.name == null || !route.name.trim().length ? $t('Sin nombre') : route.name }}
                                                h3 {{ $t('Hora de partida') }}: {{ route.hour }}
                                                span.d-block {{ $t('Fechas disponibles') }}: {{ route.dates.map(d => d.date).join(', ') }}
                                                span.d-block {{ $t('Cupos disponibles') }}: {{ route.quota - route.countQuota }}/{{ route.quota }}
                                            .sheet-content
                                                l-map(
                                                    :options="{attributionControl:false}"
                                                    :ref="'previewMapRoutesAvailable'+i" 
                                                    :zoom="2"
                                                    style="min-height:300px;z-index:1;max-height:300px;"
                                                )
                                            .sheet-content
                                                v-btn.primary(depressed rounded @click.native="join(route)") {{ $t('Unirme a esta ruta') }}
                                    v-flex(xs12)
                                        infinite-loading(:infinite="infiniteAvailable" ref="infiniteAvailable")
                                .not-found.my-4(v-else)
                                    h3 {{ $t('No se encontraron rutas disponibles') }}
                        v-tab-item(:value="1")
                            template(v-if="preload")
                                h3.my-2 {{$t('Cargando')}}...
                            template(v-else)
                                v-layout.my-2(row wrap v-if="routesAssoc.length")
                                    v-flex(xs12 sm6 md4 lg3 v-for="(route, i) in routesAssoc" :key="route.uuid")
                                        .sheet.grey.lighten-3
                                            .sheet-title.mb-3
                                                v-avatar.mr-2(:size="20")
                                                    img(v-if="photoUrl(route.user.photo).length" :src="photoUrl(route.user.photo)")
                                                    v-gravatar(v-else-if="Object.keys(route.user).length" :email="route.user.email")
                                                | {{ route.user.names }}
                                                h3.mt-3 {{ route.name == null || !route.name.trim().length ? $t('Sin nombre') : route.name }}
                                                h3 {{ $t('Hora de partida') }}: {{ route.hour }}
                                                span.d-block {{ $t('Fechas disponibles') }}: {{ route.dates.map(d => d.date).join(', ') }}
                                            .sheet-content
                                                l-map(
                                                    :options="{attributionControl:false}"
                                                    :ref="'previewMapRoutesAssoc'+i" 
                                                    :zoom="2"
                                                    style="min-height:300px;z-index:1;max-height:300px;"
                                                )
                                            .sheet-content
                                                // Solo en pendiente
                                                v-alert.mb-3(v-if="route.state == 0" outline :value="true" color="warning" icon="warning") {{ $t('Esta ruta esta pendiente de aprobación') }}
                                                .btns.btns-right
                                                    //- Solo en aprobación
                                                    v-btn.primary(v-if="route.state == 1" small depressed rounded @click.native="$refs.detailRoute.open(route)") {{ $t('Ver detalle') }}
                                                    v-btn.primary(v-if="route.state == 1" small depressed rounded @click="$refs.disassocRoute.open(route)") {{ $t('Salirme de esta ruta') }}
                                                    v-btn.primary(v-if="route.state == 0" small depressed rounded @click="$refs.disassocRoute.open(route)") {{ $t('Cancelar solicitud') }}
                                    v-flex(xs12)
                                        infinite-loading(:infinite="infiniteAssoc" ref="infiniteAssoc")
                                .not-found.my-4(v-else)
                                    h3 {{ $t('No se encontraron rutas compartidas') }}
                        v-tab-item(:value="2")
                            template(v-if="preload")
                                h3.my-2 {{$t('Cargando')}}...
                            template(v-else)
                                v-layout.my-2(row wrap v-if="routes.length")
                                    v-flex(xs12 sm6 md4 lg3 v-for="(route, i) in routes" :key="route.uuid")
                                        .sheet.grey.lighten-3
                                            .sheet-options
                                                v-menu(left)
                                                    v-icon(color='primary' dark slot='activator') more_vert
                                                    v-list.sheet-menu
                                                        v-list-tile.white--text(@click="$refs.detailUsersRoute.open(route)")
                                                            v-list-tile-title {{ $t('Usuarios asociados') }}
                                                        v-list-tile.white--text(@click="$refs.manageRoute.open(route)")
                                                            v-list-tile-title {{ $t('Editar') }}
                                                        v-list-tile.white--text(@click="$refs.deleteRoute.open(route)")
                                                            v-list-tile-title {{ $t('Eliminar') }}
                                            .sheet-title.mb-2
                                                h3 {{ route.name == null || !route.name.trim().length ? $t('Sin nombre') : route.name }}
                                                h3 {{ $t('Hora de partida') }}: {{ route.hour }}
                                                span.d-block {{ $t('Fechas disponibles') }}: {{ route.dates.map(d => d.date).join(', ') }}
                                                span.d-block {{ $t('Cupos disponibles') }}: {{ route.quota - route.countQuota }}/{{ route.quota }}
                                            .sheet-content
                                                l-map(
                                                    :options="{attributionControl:false}"
                                                    :ref="'previewMapRoutes'+i" 
                                                    :zoom="2"
                                                    style="min-height:300px;z-index:1;max-height:300px;"
                                                )
                                    v-flex(xs12)
                                        infinite-loading(:infinite="infinite" ref="infinite")
                                .not-found.my-4(v-else)
                                    h3 {{ $t('No se encontraron rutas creadas') }}
                                v-btn(@click.native="$refs.manageRoute.open()" fixed dark fab bottom right color='primary')
                                    v-icon add
            manage-route(ref="manageRoute" @success="fetch")
            detail-route(ref="detailRoute")
            detail-users-route(ref="detailUsersRoute" @update-quota="updateQuota")
            confirm(ref="deleteRoute" @action="deleteRoute" :title="$t('Eliminar ruta')" :message="$t('¿Estás seguro de eliminar esta ruta?')")
            confirm(ref="disassocRoute" @action="disassocRoute" :title="$t('Salirme de esta ruta')" :message="$t('¿Estás seguro de salir de esta ruta?')")
</template>

<script>

    import auth from 'mixins/auth'
    import _ from 'lodash/debounce'
    import fn from 'mixins/fn'
    import ManageRoute from 'components/routes/ManageRoute'
    import DetailRoute from 'components/routes/DetailRoute'
    import DetailUsersRoute from 'components/routes/DetailUsersRoute'
    import config from 'src/config'

    export default {
        mixins: [auth, fn],
        metaInfo() {
            return {
                title: this.$t('Rutas compartidas')
            }
        },
        data() {
            return {

                // Tabs
                tab: 0,

                preload: false,

                // Mis rutas
                limit : this.$root.limit,
                offset: 0,
                routes: [],

                // Rutas disponibles
                limitAvailable : this.$root.limit,
                offsetAvailable: 0,
                routesAvailable: [],

                // Rutas compartidas
                limitAssoc : this.$root.limit,
                offsetAssoc: 0,
                routesAssoc: [],

                startIcon: {},
                endIcon: {},

                alertMsj    : false,
                alertColor  : '',
                alertText   : '',

                filter: {},
                filterSchema: {
                    query : '',
                    hour  : null,
                    from  : null,
                    to    : null,
                    gender: '',
                    ages  : ''
                },
                drawerRight : false,
                timeModal: false,
                modalDateStart: false,
                modalDateEnd: false,
                gender: [
                    {
                        text: 'Masculino',
                        id: 1
                    },
                    {
                        text: 'Femenino',
                        id: 0
                    },
                    {
                        text: 'Otro',
                        id: 2
                    }
                ],
                ages: [
                    {
                        text: 'Hasta 18',
                        id: 1
                    },
                    {
                        text: 'De 19 a 30',
                        id: 2
                    },
                    {
                        text: 'De 31 a 40',
                        id: 3
                    },
                    {
                        text: 'De 41 a 50',
                        id: 4
                    },
                    {
                        text: 'De 51 a 60',
                        id: 5
                    },
                    {
                        text: 'De 61 a 80',
                        id: 6
                    },
                    {
                        text: 'Mayor a 80',
                        id: 7
                    }
                ]
            }
        },
        watch: {
            tab(){
                if(this.tab == 0){
                    this.cleanFilter()
                    this.fetchAvailable()
                }
                if(this.tab == 1){
                    this.cleanFilter()
                    this.fetchAssoc()
                }
                if(this.tab == 2){
                    this.cleanFilter()
                    this.fetch()
                }
            },
            filter: {
                handler(){
                    
                    this.preload = true

                    if(this.tab == 0){
                        this.routesAvailable = []
                        this.fetchAvailable()
                    }

                    if(this.tab == 1){
                        this.routesAssoc = []
                        this.fetchAssoc()
                    }

                    if(this.tab == 2){
                        this.routes = []
                        this.fetch()
                    }
                },
                deep: true
            },
            async routes(){
                this.renderPreviewMap(this.routes, 'Routes')
            },
            async routesAvailable(){
                this.renderPreviewMap(this.routesAvailable, 'RoutesAvailable')
            },
            async routesAssoc(){
                this.renderPreviewMap(this.routesAssoc, 'RoutesAssoc')
            }
        },
        mounted(){

            this.l = require('leaflet')
            this.startIcon = this.l.divIcon({
                className: 'material-icons primary--text',
                html: 'home'
            })

            this.endIcon = this.l.divIcon({
                className: 'material-icons primary--text',
                html: 'flag'
            })
        },
        methods: {
            cleanFilter(){
                this.filter = Object.assign({}, this.filterSchema)
            },
            async renderPreviewMap(arr, key){

                await this.$nextTick()

                for(let i in arr){

                    let refMap = this.$refs['previewMap'+key+i][0]

                    if(refMap.setMap){
                        continue
                    }

                    let roadMap = this.l.gridLayer.googleMutant({
                        maxZoom: 24,
                        type: 'roadmap'
                    })

                    roadMap.addTo(refMap.mapObject)
                    let preview = this.l.polyline(arr[i].preview, {color: '#169CB3'}).addTo(refMap.mapObject)
                    refMap.mapObject.fitBounds(preview.getBounds())

                    this.l.marker(arr[i].start, {icon: this.startIcon}).addTo(refMap.mapObject)
                    this.l.marker(arr[i].end, {icon: this.endIcon}).addTo(refMap.mapObject)
                    
                    refMap.setMap = true
                }
            },
            fetch(infinite = false){

                if(infinite){
                    this.offset += this.limit
                }else{
                    this.preload = true
                    this.routes = []
                    this.offset = 0
                    this.$refs.infinite && this.$refs.infinite.reset()
                }

                let filter = {
                    limit : this.limit,
                    offset: this.offset,
                    filter: this.filter
                }

                this.$api(this, (xhr) => {
                    xhr.get('/routes', { params: filter }).then((r) => {

                        if(!infinite) this.preload = false
                        let data = r.data

                        if(infinite){
                            if(data.response){
                                this.routes = this.routes.concat(data.data);
                                this.$refs.infinite && this.$refs.infinite.loaded()
                            }else{
                                this.$refs.infinite && this.$refs.infinite.complete()
                            }
                        }else{
                            this.routes = (data.response ? data.data : [])
                        }
                    }).catch(() => {})
                })
            },
            infinite(){
                setTimeout(() => this.fetch(true) , 500)
            },
            fetchAvailable(infinite = false){

                if(infinite){
                    this.offsetAvailable += this.limitAvailable
                }else{
                    this.preload = true
                    this.routesAvailable = []
                    this.offsetAvailable = 0
                    this.$refs.infiniteAvailable && this.$refs.infiniteAvailable.reset()
                }

                let filter = {
                    limit : this.limitAvailable,
                    offset: this.offsetAvailable,
                    filter: this.filter
                }

                this.$api(this, (xhr) => {
                    xhr.get('/routes/available', { params: filter }).then((r) => {

                        if(!infinite) this.preload = false
                        let data = r.data

                        if(infinite){
                            if(data.response){
                                this.routesAvailable = this.routesAvailable.concat(data.data);
                                this.$refs.infiniteAvailable && this.$refs.infiniteAvailable.loaded()
                            }else{
                                this.$refs.infiniteAvailable && this.$refs.infiniteAvailable.complete()
                            }
                        }else{
                            this.routesAvailable = (data.response ? data.data : [])
                        }
                    }).catch(() => {})
                })
            },
            infiniteAvailable(){
                setTimeout(() => this.fetchAvailable(true) , 500)
            },
            fetchAssoc(infinite = false){

                if(infinite){
                    this.offsetAssoc += this.limitAssoc
                }else{
                    this.preload = true
                    this.routesAssoc = []
                    this.offsetAssoc = 0
                    this.$refs.infiniteAssoc && this.$refs.infiniteAssoc.reset()
                }

                let filter = {
                    limit : this.limitAssoc,
                    offset: this.offsetAssoc,
                    filter: this.filter
                }

                this.$api(this, (xhr) => {
                    xhr.get('/routes/assoc', { params: filter }).then((r) => {

                        if(!infinite) this.preload = false
                        let data = r.data

                        if(infinite){
                            if(data.response){
                                this.routesAssoc = this.routesAssoc.concat(data.data);
                                this.$refs.infiniteAssoc && this.$refs.infiniteAssoc.loaded()
                            }else{
                                this.$refs.infiniteAssoc && this.$refs.infiniteAssoc.complete()
                            }
                        }else{
                            this.routesAssoc = (data.response ? data.data : [])
                        }
                    }).catch(() => {})
                })
            },
            infiniteAssoc(){
                setTimeout(() => this.fetchAssoc(true) , 500)
            },
            join(route){

                let post = {
                    uuid: route.uuid
                }

                this.$Progress.start()
                this.$api(this, (xhr) => {
                    xhr.post('/routes/assoc', this.$qs.stringify(post)).then((r) => {

                        let data = r.data

                        this.$Progress.finish()
                        this.alertMsj = true

                        if(data.response){

                            this.alertColor = 'success'
                            this.alertText  = data.message
                            this.alertModal = false

                            this.fetchAvailable()

                        }else{
                            this.alertColor = 'error'
                            this.alertText = data.message
                        }

                    }).catch(() => {
                        this.$Progress.finish()
                    })
                })
            },
            photoUrl(photo){
                if(photo != null && photo.length){
                    return config.uploaduser + photo
                }
                return ''
            },
            deleteRoute({uuid, userid}){

                if(!(this.profile.uuid == userid)){
                    return
                }

                this.$refs.deleteRoute.$emit('playLoader')
                this.$api(this, (xhr) => {
                    xhr.delete('/routes?uuid=' + uuid).then((r) => {
                        
                        this.$refs.deleteRoute.$emit('stopLoader')
                        this.alertMsj = true

                        let data = r.data

                        if(data.response){
                            this.alertColor = 'success'
                            this.alertText = data.message
                            this.$refs.deleteRoute.close()
                            this.fetch()
                        }else{
                            this.alertColor = 'error'
                            this.alertText = data.message
                        }
                    }).catch(() => {})
                })
            },
            disassocRoute({uuid}){

                this.$refs.disassocRoute.$emit('playLoader')
                this.$api(this, (xhr) => {
                    xhr.delete('/routes/assoc?uuid=' + uuid).then((r) => {
                        
                        this.$refs.disassocRoute.$emit('stopLoader')
                        this.alertMsj = true

                        let data = r.data

                        if(data.response){
                            this.alertColor = 'success'
                            this.alertText = data.message
                            this.$refs.disassocRoute.close()
                            this.fetchAssoc()
                        }else{
                            this.alertColor = 'error'
                            this.alertText = data.message
                        }
                    }).catch(() => {})
                })
            },
            updateQuota(data){
                
                let routeidx = this.routes.findIndex(r => r.uuid == data.routeid)
                this.routes[routeidx].countQuota = data.increment ? this.routes[routeidx].countQuota + 1 : this.routes[routeidx].countQuota - 1 
            }
        },
        components: {
            ManageRoute,
            DetailRoute,
            DetailUsersRoute
        }
    }

</script>
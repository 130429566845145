<template lang="pug">
    div
        v-snackbar(
        :timeout="3000"
        :top="true"
        :right="true"
        :color="saveColor"
        v-model="saveMsj")
            | {{ saveText }}
            v-btn(flat dark @click.native="saveMsj = false") {{ $t('cerrar') }}
        v-dialog(lazy max-width="600px" v-model="modal" persistent)
            v-card(v-if="modal")
                v-card-title 
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="modal=false")
                            v-icon close
                    h3.full-width {{ $t('Ruta compartida') }}
                v-card-text
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}...
                    template(v-else)
                        v-layout(row wrap)
                            v-flex(xs12)
                                v-text-field.required(
                                    required 
                                    v-model="model.name" 
                                    type="text" 
                                    :label="$t('Nombre de la ruta')"
                                )
                            v-flex(xs12)
                                v-dialog.mb-2(ref='dialogDate' v-model='modalDate' lazy full-width width='290px' :return-value.sync='date')
                                    v-text-field.required(required slot='activator' :label='$t("Fechas disponibles")' v-model='date' readonly)
                                    v-date-picker(:allowed-dates="allowedDates" :locale="$root.currentLanguage" v-model='date' scrollable)
                                        v-spacer
                                        v-btn(flat color='primary' @click.stop='modalDate = false') {{$t('Cancelar')}}
                                        v-btn(flat color='primary' @click.stop='$refs.dialogDate.save(date)') {{$t('OK')}}
                                .btns.btns-right
                                    v-btn.primary(small @click="addDate") 
                                        v-icon add
                                        | {{ $t('Agregar') }}
                                template(v-if="model.dates.length")
                                    h3 {{ $t('Fechas disponibles') }}
                                    v-list
                                        v-list-tile(v-for="(d, i) in model.dates" :key="'d'+i")
                                            v-list-tile-content
                                                | {{ d.date }}
                                            v-list-tile-action
                                                v-icon.cur-p(@click="model.dates.splice(i, 1)") close
                            v-flex(xs12)
                                v-dialog(
                                    ref="timePicker"
                                    persistent
                                    v-model="timeModal"
                                    lazy
                                    full-width
                                    width="320px"
                                    :return-value.sync='model.hour'
                                )
                                    v-text-field.required(
                                        required
                                        slot="activator"
                                        :label="$t('Hora de partida')"
                                        v-model="model.hour"
                                        readonly
                                    )
                                    v-time-picker(v-model="model.hour" actions)
                                        v-spacer
                                        v-btn(flat color="primary" @click="timeModal=false") {{ $t('Cancelar') }}
                                        v-btn(flat color="primary" @click="$refs.timePicker.save(model.hour)") {{ $t('Guardar') }}
                            v-flex(xs12)
                                v-text-field.required(
                                    required 
                                    v-model="model.quota" 
                                    type="number" 
                                    :label="$t('Cupos disponibles')"
                                )
                            v-flex(xs12)
                                h3.full-width.mt-3.mb-3 {{ $t('Punto de partida') }}
                                l-map(
                                    v-if="showMap"
                                    :options="{attributionControl:false}" 
                                    :center="center" 
                                    :zoom="zoom" 
                                    ref="mapStart" 
                                    style="min-height:400px;z-index:1;max-height:400px;"
                                )
                            v-flex(xs12)
                                h3.full-width.mt-3.mb-3 {{ $t('Punto de llegada') }}
                                l-map(
                                    v-if="showMap"
                                    :options="{attributionControl:false}" 
                                    :center="center" 
                                    :zoom="zoom" 
                                    ref="mapEnd" 
                                    style="min-height:400px;z-index:1;max-height:400px;"
                                )
                            v-flex(xs12)
                                h3.full-width.mt-3.mb-3 {{ $t('Vista previa de la ruta') }}
                                l-map(
                                    v-if="showMap"
                                    :options="{attributionControl:false}"
                                    ref="previewMap" 
                                    :center="center" 
                                    :zoom="zoom"
                                    style="min-height:400px;z-index:1;max-height:400px;"
                                )
                            v-flex(xs12)
                                h3.full-width.mt-3 {{ $t('Etiquetas de la ruta') }}
                                v-select(v-model='model.tag' @input="addTag" :label="$t('Oprime INTRO por cada etiqueta')" append-icon="" hide-details chips tags clearable)
                                    template(slot='selection' slot-scope='data')
                                        v-chip(:selected='data.selected' close small @input='removeTag(data.item)')
                                            span {{ data.item.tag }}
                v-card-actions
                    .btns.btns-right
                        v-btn(color="secondary", small, @click.native="modal = false") {{$t('Cancelar')}}
                        v-btn(:loading="loadingBtn" color="primary" large @click.native="save") {{$t('Guardar')}}
</template>

<script>

    import config from 'src/config'
    export default {
        data() {
            return {
                saveMsj    : false,
                saveColor  : '',
                saveText   : '',
                modal      : false,
                modelSchema: {
                    name  : '',
                    hour  : null,
                    quota : 1,
                    tag   : [],
                    dates : []
                },
                model      : {},
                preload    : false,
                loadingBtn : false,
                timeModal  : false,
                modalDate  : false,
                date       : '',

                // Mapa
                center  : null,
                zoom    : 5,
                showMap : false,

                markers: {
                    start       : {},
                    end         : {},
                    preview     : {},
                    previewStart: {},
                    previewEnd  : {}
                },
                startIcon: {},
                endIcon: {}
            }
        },
        watch: {
            async modal(){

                if(this.modal){

                    await this.$nextTick()

                    this.l = require('leaflet')
                    await this.getLatLng()

                    this.startIcon = this.l.divIcon({
                        className: 'material-icons primary--text',
                        html: 'home'
                    })

                    this.endIcon = this.l.divIcon({
                        className: 'material-icons primary--text',
                        html: 'flag'
                    })

                    this.$refs.mapStart.mapObject.on('click', (e) => {
                        
                        let map = this.$refs.mapStart.mapObject
                        map.removeLayer(this.markers.start)
                        this.markers.start = this.l.marker(e.latlng).addTo(map)
                        this.renderRoute()
                    })

                    this.$refs.mapEnd.mapObject.on('click', (e) => {
                        
                        let map = this.$refs.mapEnd.mapObject
                        map.removeLayer(this.markers.end)
                        this.markers.end = this.l.marker(e.latlng).addTo(map)
                        this.renderRoute()
                    })


                    // Datos por defecto
                    if(this.model.hasOwnProperty('uuid')){

                        let mapStart = this.$refs.mapStart.mapObject
                        mapStart.removeLayer(this.markers.start)

                        let mapEnd = this.$refs.mapEnd.mapObject
                        mapEnd.removeLayer(this.markers.end)

                        this.markers.start = this.l.marker({lat: this.model.start[0], lng: this.model.start[1]}).addTo(mapStart)
                        this.markers.end   = this.l.marker({lat: this.model.end[0], lng: this.model.end[1]}).addTo(mapEnd)    

                        this.renderRoute()
                    }
                }
            }
        },
        methods: {
            addGeocoder(map, marker){

                this.l.Control.geocoder({
                    defaultMarkGeocode: false,
                    placeholder       : this.$t('Buscar'),
                    errorMessage      : this.$t('No se encontraron resultados'),
                    geocoder          : new this.l.Control.Geocoder.Google({
                        geocodingQueryParams: {
                            key: config.mapsKey
                        }
                    })
                }).on('markgeocode', (e) => {

                    const latLng = e.geocode.center
                    this.setMarker(latLng, map, marker)
                    this.renderRoute()

                }).addTo(map)
            },
            renderRoute(){

                const polyUtil = require('polyline-encoded')
                
                let start = this.markers.start.hasOwnProperty('_latlng') ? this.markers.start.getLatLng() : null
                let end   = this.markers.end.hasOwnProperty('_latlng') ? this.markers.end.getLatLng() : null

                if(start != null && end != null){

                    let directionsService = new google.maps.DirectionsService()
                    let startMap = new google.maps.LatLng(start.lat, start.lng)
                    let endMap   = new google.maps.LatLng(end.lat, end.lng)

                    let req = {
                        origin: startMap,
                        destination: endMap,
                        travelMode: 'DRIVING'
                    }

                    directionsService.route(req, (result, status) => {

                        if(status == 'OK'){
                            
                            let polyline = result.routes[0].overview_polyline
                            let latLngs = polyUtil.decode(polyline)

                            let previewMap = this.$refs.previewMap.mapObject

                            previewMap.removeLayer(this.markers.preview)
                            previewMap.removeLayer(this.markers.previewStart)
                            previewMap.removeLayer(this.markers.previewEnd)

                            this.markers.preview = this.l.polyline(latLngs, {color: '#169CB3'}).addTo(previewMap)
                            previewMap.fitBounds(this.markers.preview.getBounds())

                            this.markers.previewEnd = this.l.marker(this.markers.end.getLatLng(), {icon: this.endIcon}).addTo(previewMap)
                            this.markers.previewStart = this.l.marker(this.markers.start.getLatLng(), {icon: this.startIcon}).addTo(previewMap)
                        }
                    })
                }
            },
            setMarker(latLng, map, marker){

                map.removeLayer(this.markers[marker])
                this.markers[marker] = this.l.marker({lat: latLng.lat, lng: latLng.lng}).addTo(map)
                map.setView(latLng)
            },
            async open(item = this.modelSchema){

                this.model = this._.cloneDeep(item)
                this.modal = true
                this.preload = false
            },
            async getLatLng(){

                return new Promise(async (resolve) => {

                    let defaultLatLng = [4.6533326, -74.083652]
                    let latLng        = defaultLatLng

                    if('geolocation' in navigator){

                        latLng = await new Promise(resolve => {
                            navigator.geolocation.getCurrentPosition((pos) => {

                                let coords = pos.coords
                                resolve([coords.latitude, coords.longitude])
                            }, () => {
                                resolve(defaultLatLng)
                            })
                        })
                    }

                    this.center  = this.l.latLng(latLng)
                    this.showMap = true

                    setTimeout(() => {

                        let roadMapStart = this.l.gridLayer.googleMutant({
                            maxZoom: 24,
                            type: 'roadmap'
                        })

                        let roadMapEnd = this.l.gridLayer.googleMutant({
                            maxZoom: 24,
                            type: 'roadmap'
                        })

                        let roadMapPreview = this.l.gridLayer.googleMutant({
                            maxZoom: 24,
                            type: 'roadmap'
                        })

                        roadMapStart.addTo(this.$refs.mapStart.mapObject)
                        roadMapEnd.addTo(this.$refs.mapEnd.mapObject)
                        roadMapPreview.addTo(this.$refs.previewMap.mapObject)

                        this.addGeocoder(this.$refs.mapStart.mapObject, 'start')
                        this.addGeocoder(this.$refs.mapEnd.mapObject, 'end')
                    })

                    resolve()
                })
            },
            save(){

                try{

                    if(!Object.keys(this.markers.previewStart).length){
                        throw this.$t("Seleccione un punto de partida")
                    }

                    if(!Object.keys(this.markers.previewStart).length){
                        throw this.$t("Seleccione un punto de llegada")
                    }

                    let start   = this.markers.previewStart.getLatLng()
                    let end     = this.markers.previewEnd.getLatLng()
                    let preview = this.markers.preview.getLatLngs()

                    let post = {
                        uuid    : this.model.hasOwnProperty('uuid') ? this.model.uuid : '',
                        hour    : this.model.hour,
                        start   : JSON.stringify([start.lat, start.lng]),
                        end     : JSON.stringify([end.lat, end.lng]),
                        preview : JSON.stringify(preview),
                        quota   : this.model.quota,
                        name    : this.model.name,
                        tag     : JSON.stringify(this.model.tag),
                        dates   : JSON.stringify(this.model.dates)
                    }

                    this.loadingBtn = true
                    this.$api(this, (xhr) => {
                        xhr.post('/routes', this.$qs.stringify(post)).then((r) => {

                            let data = r.data
                            this.loadingBtn = false
                            this.saveMsj = true

                            if(data.response){

                                this.saveColor = 'success'
                                this.saveText  = data.message
                                this.saveModal = false

                                this.modal = false
                                this.$emit('success')

                            }else{
                                this.saveColor = 'error'
                                this.saveText = data.message
                            }
                        }).catch(() => {})
                    })

                }catch(e){
                    
                    this.saveMsj   = true
                    this.saveColor = 'error'
                    this.saveText  = e
                }
            },
            addTag(){
                this.model.tag = this.model.tag.map((tag) => {
                    if(typeof tag == 'string'){
                        tag = {uuid : '', tag  : tag}
                    }
                    return tag
                })
            },
            removeTag(item) {
                this.model.tag.splice(this.model.tag.indexOf(item), 1)
            },
            addDate(){

                try{

                    if(!this.date.length){
                        throw "Seleccione una fecha"
                    }

                    if(this.model.dates.length >= 5){
                        throw "Solo puede seleccionar máximo 5 fechas por ruta"
                    }

                    if(this.model.dates.some(d => d.date == this.date)){
                        throw "La fecha ya ha sido agregada"
                    }

                    this.model.dates.push({date: this.date})
                    this.date = ''

                }catch(e){

                    this.saveColor = 'error'
                    this.saveText  = e
                    this.saveMsj   = true
                }
            },
            allowedDates(val){
                
                const d = this.$moment()
                const date = this.$moment()

                d.add(15, 'days')

                return !this.model.dates.some(d => d.date == val) && val >= date.format('YYYY-MM-DD') && val <= d.format('YYYY-MM-DD')
            }
        }
    }

</script>
<template lang="pug">
    div
        v-dialog(lazy max-width="600px" v-model="modal" persistent)
            v-card(v-if="modal")
                v-card-title 
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="modal=false")
                            v-icon close
                    h3.full-width {{ $t('Ruta compartida') }}
                v-card-text
                    l-map(
                        :options="{attributionControl:false}"
                        ref="previewMap" 
                        :zoom="2"
                        style="min-height:400px;z-index:1;max-height:400px;"
                    )
                    h4.mt-3.d-block {{ $t('Hora de partida') }}: {{ current.hour }}
                    h4.d-block {{ $t('Fechas disponibles') }}: {{ current.dates.map(d => d.date).join(', ') }}
                    h4.d-block {{ $t('Cupos disponibles') }}: {{ current.quota - current.countQuota }}/{{ current.quota }}
                    div.my-3
                        v-chip(v-for="tag in current.tag" small :key="tag.uuid")
                            span {{ tag.tag }}
                    p.mt-3 {{ $t('Contacta al creador de la ruta compartida para coordinar los detalles de la recogida') }}
                    h3.mb-2 {{ $t('Información del creador de la ruta') }}
                    v-divider.primary.x1 
                    v-avatar.mr-2.mt-2(:size="36")
                        img(v-if="photoUrl(current.user.photo).length" :src="photoUrl(current.user.photo)")
                        v-gravatar(v-else-if="Object.keys(current.user).length" :email="current.user.email")
                    | {{ current.user.names }}
                    //.stars.mx-0.my-2
                        star-rating
                    span.mt-2.d-block {{ $t('País') }}: {{ current.user.country }}
                    span.d-block {{ $t('Estado') }}: {{ current.user.state }}
                    span.d-block {{ $t('Ciudad') }}: {{ current.user.city }}
                    span.d-block {{ $t('Edad') }}: {{ current.user.age }}
                    span.d-block {{ $t('Genéro') }}: {{ current.user.gender }}
                    span.d-block {{ $t('Documento') }}: {{ current.user.document }}
                    span.d-block {{ $t('Teléfono') }}: {{ current.user.phone }}
                    span.d-block
                        | {{ $t('Whatsapp') }}: 
                        a(:href="current.user.whatsapp" target="_blank") {{ current.user.whatsapp }}  
                    span.d-block(v-for="(zone, i) in current.user.zones" :key="'z'+i")
                        | {{ zone.division }}: {{ zone.name }}                
                    span.d-block.mt-2(v-for="(f, i) in form" :key="i")
                        | {{ f.label }}: {{ f.value }}
                    h3.my-2.mt-4 {{ $t('Usuarios asociados a esta ruta') }} 
                    v-divider.primary.x1 
                    users-route(:routeid="current.uuid")
</template>

<script>

    import config from 'src/config'
    import UsersRoute from 'components/routes/UsersRoute.vue'

    export default {
        components:{
            UsersRoute
        },
        data() {
            return {
                modal    : false,
                current  : {},
                form     : []
            }
        },
        mounted(){

            this.l = require('leaflet')

            this.startIcon = this.l.divIcon({
                className: 'material-icons primary--text',
                html: 'home'
            })

            this.endIcon = this.l.divIcon({
                className: 'material-icons primary--text',
                html: 'flag'
            })
        },
        methods: {
            async open(item){

                this.current = item
                this.modal   = true

                await this.$nextTick()

                let roadMap = this.l.gridLayer.googleMutant({
                    maxZoom: 24,
                    type: 'roadmap'
                })

                roadMap.addTo(this.$refs.previewMap.mapObject)

                let preview = this.l.polyline(this.current.preview, {color: '#169CB3'}).addTo(this.$refs.previewMap.mapObject)
                this.$refs.previewMap.mapObject.fitBounds(preview.getBounds())

                this.l.marker(this.current.start, {icon: this.startIcon}).addTo(this.$refs.previewMap.mapObject)
                this.l.marker(this.current.end, {icon: this.endIcon}).addTo(this.$refs.previewMap.mapObject)

                await this.fetchForm()
            },
            photoUrl(photo){
                if(photo != null && photo.length){
                    return config.uploaduser + photo
                }
                return ''
            },
            fetchForm() {

                return new Promise((resolve) => {

                    this.$api(this, (xhr) => {
                        xhr.get('/network/form/user', {
                            params: {
                                networkid : this.current.user.networkid,
                                userid    : this.current.user.uuid
                            }
                        }).then((r) => {

                            let data = r.data
                            if (data.response) {
                                
                                let customForm = data.data
                                this.form = customForm.custom
                            }
                            resolve()
                        }).catch(() => resolve())
                    })
                })
            }
        }
    }
</script>
<template lang="pug">
    div
        v-snackbar(
        :timeout="3000"
        :top="true"
        :right="true"
        :color="alertColor"
        v-model="alertMsj")
            | {{ alertText }}
            v-btn(flat dark @click.native="alertMsj = false") {{ $t('cerrar') }}
        template(v-if="preload")
            h3.mb-2.mt-3 {{$t('Cargando')}}...
        template(v-else)
            v-layout.my-2(row wrap v-if="users.length")
                v-flex(xs12 v-for="(user, i) in users" :key="user.uuid")
                    .sheet.grey.lighten-3
                        .sheet-title.mb-3
                            v-avatar.mr-2(:size="20")
                                img(v-if="photoUrl(user.photo).length" :src="photoUrl(user.photo)")
                                v-gravatar(v-else-if="Object.keys(user).length" :email="user.email")
                            | {{ user.names }}
                            h4.mb-3 {{ user.email }}
                            .btns.btns-right(v-if="owner")
                                v-btn.primary(small depressed @click="$refs.form.open(user)") {{ $t('Ver datos adicionales') }}
                                // Solo en pendiente
                                v-btn.primary(v-if="user.routeState == 0" small depressed @click="approveUser(user)" :loading="user.loadingApprove") {{ $t('Aprobar usuario') }}
                                v-btn.primary(small depressed @click="deleteUser(user)" :loading="user.loadingDelete") {{ $t('Eliminar usuario') }}
                v-flex(xs12)
                    infinite-loading(:infinite="infinite" ref="infinite")
            .not-found.my-4(v-else)
                h3 {{ $t('No se encontraron usuarios asociados') }}
        form-data(ref="form" :userdata="true")
</template>

<script>

import config from 'src/config'
export default {
    props:{
        routeid: {
            type: String,
            default: ''
        },
        owner: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            users    : [],
            limit    : this.$root.limit,
            offset   : 0,
            preload  : false,

            alertMsj    : false,
            alertColor  : '',
            alertText   : '',
        }
    },
    computed:{
        profile(){
            return this.$root.profile
        }
    },
    watch: {
        routeid:{
            immediate: true,
            handler(){
                this.fetch()
            }
        }
    },
    methods: {
        fetch(infinite = false){

            if(infinite){
                this.offset += this.limit
            }else{
                this.preload = true
                this.users = []
                this.offset = 0
                this.$refs.infinite && this.$refs.infinite.reset()
            }

            let filter = {
                limit : this.limit,
                offset: this.offset,
                uuid  : this.routeid
            }

            this.$api(this, (xhr) => {
                xhr.get('/routes/users', { params: filter }).then((r) => {

                    if(!infinite) this.preload = false
                    let data = r.data

                    if(infinite){
                        if(data.response){
                            this.users = this.users.concat(data.data);
                            this.$refs.infinite && this.$refs.infinite.loaded()
                        }else{
                            this.$refs.infinite && this.$refs.infinite.complete()
                        }
                    }else{
                        this.users = (data.response ? data.data : [])
                    }
                }).catch(() => {})
            })
        },
        infinite(){
            setTimeout(() => this.fetch(true) , 500)
        },
        photoUrl(photo){
            if(photo != null && photo.length){
                return config.uploaduser + photo
            }
            return ''
        },
        deleteUser(user){

            if(!this.owner){
                return
            }

            let params = {
                routeid : this.routeid,
                userid  : user.uuid
            }

            user.loadingDelete = true
            this.$api(this, (xhr) => {
                xhr.delete('/routes/users', {params}).then((r) => {

                    let data = r.data

                    user.loadingDelete = false
                    this.alertMsj = true

                    if(data.response){

                        this.alertColor = 'success'
                        this.alertText  = data.message
                        this.alertModal = false

                        this.fetch()

                        if(user.routeState != 0){
                            this.$emit('update-quota', {routeid: this.routeid, increment: false})
                        }

                    }else{
                        this.alertColor = 'error'
                        this.alertText = data.message
                    }

                }).catch(() => {
                    user.loadingDelete = false
                })
            })
        },
        approveUser(user){

            if(!this.owner){
                return
            }

            let post = {
                routeid : this.routeid,
                userid  : user.uuid
            }

            user.loadingApprove = true
            this.$api(this, (xhr) => {
                xhr.post('/routes/users/approve', this.$qs.stringify(post)).then((r) => {

                    let data = r.data

                    user.loadingApprove = false
                    this.alertMsj = true

                    if(data.response){

                        this.alertColor = 'success'
                        this.alertText  = data.message
                        this.alertModal = false

                        this.fetch()
                        this.$emit('update-quota', {routeid: this.routeid, increment: true})

                    }else{
                        this.alertColor = 'error'
                        this.alertText = data.message
                    }

                }).catch(() => {
                    user.loadingApprove = false
                })
            })
        }
    }
}
</script>
<template lang="pug">
    div
        v-dialog(lazy max-width="600px" v-model="modal" persistent)
            v-card(v-if="modal")
                v-card-title 
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="modal=false")
                            v-icon close
                    h3.full-width {{ $t('Usuarios asociados a esta ruta') }}
                v-card-text
                    users-route(
                        :owner="true" 
                        :routeid="current.uuid" 
                        @update-quota="updateQuota"
                    )
</template>

<script>

import UsersRoute from 'components/routes/UsersRoute'
export default {
    components:{
        UsersRoute
    },
    data() {
        return {
            modal    : false,
            current  : {},
        }
    },
    methods: {
        async open(item){

            this.current = item
            this.modal   = true
        },
        updateQuota(data){
            this.$emit('update-quota', data)
        }
    }
}
</script>
            